import React from 'react';

export const CuriBookmarksSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="1350"
    height="859"
    viewBox="0 0 1350 859"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1181.48 651.007C1189.46 659.127 1191.67 675.058 1178.96 693.945C1163.96 716.236 1102.32 709.946 1102.32 709.946C1102.32 709.946 1033.07 690.106 1006.07 713.897C988.018 729.8 1021.56 681.917 1000.98 704.72C987.006 720.204 972.749 753.436 968.561 736.457C965.265 723.092 952.566 756.073 959.891 733.273C967.285 710.261 978.532 697.051 990.485 676.771C1003.81 654.168 997.621 644.177 1015.68 625.723C1057.32 583.169 1087.16 635.338 1136.98 634.658C1157.53 634.378 1169.33 638.63 1181.48 651.007Z"
      fill="#DB5442"
    />
    <path
      d="M1160.96 638.952C1176.06 648.948 1174.7 671.683 1167.76 683.584C1160.82 695.485 1152.03 696.929 1138.67 699.62C1124.78 702.418 1085.02 696.236 1085.02 696.236C1085.02 696.236 1035.89 692.349 1008.89 716.14C990.84 732.043 1004.28 710.858 983.699 733.661C969.724 749.144 954.373 768.721 950.186 751.742C946.889 738.377 941.462 751.561 948.787 728.761C956.181 705.749 967.428 692.539 979.381 672.26C992.704 649.656 986.468 623.111 1002.12 600.985C1013.04 585.535 1039.95 587.978 1039.95 587.978C1039.95 587.978 1147.28 629.903 1160.96 638.952Z"
      fill="#DB5442"
    />
    <path
      d="M1165.21 649.048C1171.14 653.716 1185.53 668.235 1169.73 684.614C1153.94 700.994 1128.25 694.146 1105.22 693.086C1091.17 692.439 1082.77 685.765 1062.79 689.514C1039.98 693.796 1037.73 692.497 1011.41 715.696C993.808 731.202 986.903 745.551 966.834 767.787C953.208 782.885 937.333 779.889 933.25 763.333C930.036 750.301 928.296 741.845 935.439 719.612C942.648 697.173 953.616 684.292 965.271 664.518C978.262 642.477 979.957 622.536 997.566 604.542C1038.17 563.047 1051.99 617.526 1094.65 628.381C1113.14 633.088 1153.94 640.179 1165.21 649.048Z"
      fill="#F99746"
    />
    <path
      d="M1017.02 517.516C1021.82 511.687 1036.64 497.619 1052.68 513.762C1068.71 529.905 1061.31 555.44 1059.76 578.439C1058.81 592.473 1055.27 620.784 1055.27 620.784L1041.35 649.26L1016.58 691.015C1016.58 691.015 1021.26 724.468 1003.65 706.476C963.043 664.985 983.065 630.035 994.837 587.618C999.942 569.227 1007.91 528.59 1017.02 517.516Z"
      fill="#F99746"
    />
    <path
      d="M1041.49 506.965C1081.98 515.528 1063.64 617.634 1054.63 619.806C1054.63 619.806 1070.62 519.309 1037.19 519.206C1003.76 519.102 979.379 682.241 979.379 682.241C979.379 682.241 928.76 740.584 935.903 718.352C943.112 695.913 956.539 687.956 968.194 668.181C981.186 646.14 993.203 496.75 1041.49 506.965Z"
      fill="#DB5442"
    />
    <path
      d="M985.138 708.834C996.12 722.179 1019.2 730.537 1019.2 730.537C1019.2 730.537 993.037 773.361 973.411 783.629C949.854 795.953 934.412 772.316 919.16 751.818C904.25 731.779 903.332 716.849 915.657 700.723C935.005 675.411 975.199 639.057 975.199 639.057C975.199 639.057 964.889 684.229 985.138 708.834Z"
      fill="#61B195"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1018.33 722.355C1018.33 722.355 1018.33 722.355 1018.33 722.355L1018.34 722.356L1018.32 722.35L1018.2 722.308C1018.08 722.267 1017.9 722.201 1017.65 722.111C1017.16 721.929 1016.42 721.647 1015.49 721.266C1013.62 720.502 1011.01 719.347 1008.08 717.804C1002.12 714.665 995.306 710.158 990.593 704.431C981.732 693.663 978.771 679.367 978.212 667.151C977.936 661.123 978.254 655.804 978.641 652.002C978.834 650.106 979.043 648.6 979.199 647.587C979.278 647.081 979.343 646.699 979.386 646.454C979.408 646.332 979.425 646.244 979.434 646.192L979.443 646.145L979.444 646.141C980.178 642.438 977.738 638.986 973.992 638.43C970.245 637.874 966.611 640.427 965.874 644.132L972.659 645.138C965.874 644.132 965.874 644.134 965.873 644.136L965.872 644.142L965.87 644.155L965.862 644.193L965.84 644.311C965.821 644.408 965.797 644.543 965.766 644.713C965.706 645.052 965.624 645.534 965.529 646.145C965.34 647.366 965.1 649.107 964.881 651.26C964.444 655.557 964.085 661.548 964.397 668.369C965.014 681.849 968.292 699.401 979.68 713.238C985.948 720.855 994.479 726.324 1001.12 729.823C1004.5 731.6 1007.5 732.93 1009.68 733.821C1010.76 734.268 1011.65 734.607 1012.28 734.838C1012.59 734.954 1012.84 735.043 1013.02 735.106L1013.24 735.18L1013.3 735.203L1013.32 735.21L1013.34 735.214C1013.34 735.214 1013.34 735.215 1015.83 728.784L1013.34 735.214C1016.9 736.407 1020.9 734.496 1022.28 730.945C1023.66 727.396 1021.89 723.552 1018.34 722.356M1018.33 722.355C1018.33 722.355 1018.33 722.355 1018.33 722.355Z"
      fill="#282F33"
    />
    <path
      d="M153.671 651.007C145.695 659.127 143.49 675.058 156.199 693.945C171.198 716.236 232.841 709.946 232.841 709.946C232.841 709.946 302.089 690.106 329.09 713.897C347.138 729.8 313.594 681.917 334.175 704.72C348.15 720.204 362.408 753.436 366.595 736.457C369.891 723.092 382.591 756.073 375.265 733.273C367.872 710.261 356.624 697.051 344.671 676.771C331.348 654.168 337.535 644.177 319.477 625.723C277.835 583.169 247.992 635.338 198.178 634.658C177.628 634.378 165.83 638.63 153.671 651.007Z"
      fill="#DB5442"
    />
    <path
      d="M174.201 638.952C159.093 648.948 160.453 671.683 167.396 683.584C174.34 695.485 183.125 696.929 196.485 699.62C210.375 702.418 250.132 696.236 250.132 696.236C250.132 696.236 299.267 692.349 326.268 716.14C344.316 732.043 330.876 710.858 351.457 733.661C365.432 749.144 380.783 768.721 384.971 751.742C388.267 738.377 393.695 751.561 386.369 728.761C378.976 705.749 367.728 692.539 355.775 672.26C342.452 649.656 348.689 623.111 333.04 600.985C322.113 585.535 295.206 587.978 295.206 587.978C295.206 587.978 187.877 629.903 174.201 638.952Z"
      fill="#DB5442"
    />
    <path
      d="M169.945 649.048C164.014 653.716 149.63 668.235 165.423 684.614C181.217 700.994 206.905 694.146 229.932 693.086C243.984 692.439 252.39 685.765 272.365 689.514C295.181 693.796 297.421 692.497 323.75 715.696C341.349 731.202 348.253 745.551 368.322 767.787C381.949 782.885 397.823 779.889 401.906 763.333C405.121 750.301 406.86 741.845 399.717 719.612C392.508 697.173 381.54 684.292 369.885 664.518C356.894 642.477 355.199 622.536 337.59 604.542C296.985 563.047 283.171 617.526 240.51 628.381C222.013 633.088 181.213 640.179 169.945 649.048Z"
      fill="#F99746"
    />
    <path
      d="M318.136 517.516C313.341 511.687 298.515 497.619 282.48 513.762C266.444 529.905 273.845 555.44 275.401 578.439C276.351 592.473 279.887 620.784 279.887 620.784L293.811 649.26L318.581 691.015C318.581 691.015 313.893 724.468 331.503 706.476C372.113 664.985 352.091 630.035 340.319 587.618C335.215 569.227 327.245 528.59 318.136 517.516Z"
      fill="#F99746"
    />
    <path
      d="M293.662 506.965C253.181 515.528 271.519 617.634 280.523 619.806C280.523 619.806 264.533 519.309 297.963 519.206C331.393 519.102 355.778 682.241 355.778 682.241C355.778 682.241 406.396 740.584 399.253 718.352C392.044 695.913 378.617 687.956 366.962 668.181C353.971 646.14 341.953 496.75 293.662 506.965Z"
      fill="#DB5442"
    />
    <path
      d="M350.019 708.834C339.037 722.179 315.957 730.537 315.957 730.537C315.957 730.537 342.119 773.361 361.745 783.629C385.302 795.953 400.744 772.316 415.996 751.818C430.907 731.779 431.825 716.849 419.499 700.723C400.152 675.411 359.957 639.057 359.957 639.057C359.957 639.057 370.267 684.229 350.019 708.834Z"
      fill="#61B195"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M316.824 722.355C316.824 722.355 316.824 722.355 316.824 722.355L316.82 722.356L316.839 722.35L316.96 722.308C317.076 722.267 317.26 722.201 317.506 722.111C317.999 721.929 318.738 721.647 319.668 721.266C321.533 720.502 324.143 719.347 327.072 717.804C333.032 714.665 339.85 710.158 344.563 704.431C353.425 693.663 356.385 679.367 356.945 667.151C357.221 661.123 356.902 655.804 356.516 652.002C356.323 650.106 356.114 648.6 355.957 647.587C355.879 647.081 355.813 646.699 355.77 646.454C355.748 646.332 355.732 646.244 355.722 646.192L355.713 646.145L355.712 646.141C354.978 642.438 357.418 638.986 361.164 638.43C364.911 637.874 368.545 640.427 369.282 644.132L362.498 645.138C369.282 644.132 369.282 644.134 369.283 644.136L369.284 644.142L369.287 644.155L369.294 644.193L369.316 644.311C369.335 644.408 369.36 644.543 369.39 644.713C369.45 645.052 369.532 645.534 369.627 646.145C369.816 647.366 370.056 649.107 370.275 651.26C370.712 655.557 371.072 661.548 370.759 668.369C370.142 681.849 366.864 699.401 355.477 713.238C349.208 720.855 340.677 726.324 334.034 729.823C330.661 731.6 327.656 732.93 325.481 733.821C324.391 734.268 323.504 734.607 322.876 734.838C322.562 734.954 322.312 735.043 322.133 735.106L321.919 735.18L321.854 735.203L321.833 735.21L321.821 735.214C321.819 735.214 321.818 735.215 319.322 728.784L321.821 735.214C318.26 736.407 314.252 734.496 312.874 730.945C311.497 727.396 313.263 723.552 316.82 722.356M316.824 722.355C316.824 722.355 316.824 722.355 316.824 722.355Z"
      fill="#282F33"
    />
    <ellipse
      cx="265.674"
      cy="263.539"
      rx="265.674"
      ry="263.539"
      transform="matrix(0.999968 -0.00803993 0.00556109 0.999985 395.883 257.475)"
      fill="#F99746"
    />
    <path
      opacity="0.9"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M646.328 755.607C793.066 754.464 911.365 639.266 910.556 498.305C910.098 418.586 871.643 347.7 811.774 301.359C882.553 347.724 929.568 428.151 930.137 527.234C930.947 668.196 812.648 783.394 665.91 784.536C569.633 785.286 484.721 734.969 437.477 660.461C486.484 719.037 561.917 756.264 646.328 755.607Z"
      fill="#D84C42"
    />
    <ellipse
      cx="730.327"
      cy="265.296"
      rx="59.2277"
      ry="32.0826"
      transform="rotate(-20.1285 730.327 265.296)"
      fill="#F99746"
    />
    <ellipse
      cx="643.069"
      cy="253.203"
      rx="81.2859"
      ry="44.3122"
      transform="rotate(-59.7766 643.069 253.203)"
      fill="#F99746"
    />
    <path
      opacity="0.9"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M684.138 182.094C705.284 194.413 704.107 235.846 681.509 274.636C671.022 292.638 657.765 306.907 644.454 315.8C652.723 307.768 660.569 297.776 667.28 286.255C689.878 247.465 691.055 206.032 669.908 193.713C658.575 187.111 643.131 190.211 627.758 200.481C647.331 181.468 669.272 173.434 684.138 182.094ZM773.255 243.375C779.353 260.011 759.398 282.623 728.686 293.88C711.662 300.119 694.907 301.56 682.314 298.772C694.053 308.057 718.074 309.051 742.654 300.043C773.367 288.786 793.321 266.174 787.224 249.538C784.506 242.122 777.071 237.146 766.945 234.904C769.874 237.22 772.038 240.053 773.255 243.375Z"
      fill="#D84C42"
    />
    <path
      d="M527.283 580.189C527.066 577.817 528.933 575.771 531.314 575.771H784.05C786.371 575.771 788.217 577.719 788.093 580.036L788.004 581.687C784.554 646.142 731.284 696.637 666.736 696.637H659.899H648.775C585.996 696.637 533.565 648.788 527.84 586.271L527.283 580.189Z"
      fill="#282F33"
    />
    <path
      d="M606.418 681.524C614.683 674.855 639.877 671.414 661.794 671.37C702.019 671.289 715.749 681.524 715.749 681.524C715.749 681.524 660.654 702.082 606.418 681.524Z"
      fill="#D84C42"
    />
    <circle cx="551.478" cy="466.917" r="93.7556" fill="#F9F3EB" />
    <circle cx="552.266" cy="466.828" r="63.0667" fill="#282F33" stroke="#282F33" />
    <circle cx="774.545" cy="466.917" r="93.7556" fill="#F9F3EB" />
    <circle cx="775.016" cy="467.004" r="63.6571" fill="#282F33" stroke="#282F33" />
    <path
      d="M570.228 404.139C570.228 404.139 572.744 427.624 582.545 437.425C592.346 447.226 615.832 449.742 615.832 449.742C615.832 449.742 592.346 452.258 582.545 462.059C572.744 471.86 570.228 495.345 570.228 495.345C570.228 495.345 567.712 471.86 557.911 462.059C548.11 452.258 524.625 449.742 524.625 449.742C524.625 449.742 548.11 447.226 557.911 437.425C567.712 427.624 570.228 404.139 570.228 404.139Z"
      fill="white"
    />
    <path
      d="M793.303 404.139C793.303 404.139 795.819 427.624 805.62 437.425C815.42 447.226 838.906 449.742 838.906 449.742C838.906 449.742 815.42 452.258 805.62 462.059C795.819 471.86 793.303 495.345 793.303 495.345C793.303 495.345 790.786 471.86 780.985 462.059C771.185 452.258 747.699 449.742 747.699 449.742C747.699 449.742 771.185 447.226 780.985 437.425C790.786 427.624 793.303 404.139 793.303 404.139Z"
      fill="white"
    />
    <path
      d="M521.133 475.994C521.133 475.994 522.469 488.463 527.673 493.666C532.876 498.87 545.345 500.206 545.345 500.206C545.345 500.206 532.876 501.542 527.673 506.745C522.469 511.948 521.133 524.417 521.133 524.417C521.133 524.417 519.798 511.948 514.594 506.745C509.391 501.542 496.922 500.206 496.922 500.206C496.922 500.206 509.391 498.87 514.594 493.666C519.798 488.463 521.133 475.994 521.133 475.994Z"
      fill="white"
    />
    <path
      d="M744.051 475.994C744.051 475.994 745.387 488.463 750.591 493.666C755.794 498.87 768.263 500.206 768.263 500.206C768.263 500.206 755.794 501.542 750.591 506.745C745.387 511.948 744.051 524.417 744.051 524.417C744.051 524.417 742.716 511.948 737.512 506.745C732.309 501.542 719.84 500.206 719.84 500.206C719.84 500.206 732.309 498.87 737.512 493.666C742.716 488.463 744.051 475.994 744.051 475.994Z"
      fill="white"
    />
    <path
      d="M845.932 34.3482L797.07 10.8223C792.21 8.48246 786.621 8.16635 781.533 9.94349C776.444 11.7206 772.273 15.4454 769.936 20.2985L722.948 117.89C722.427 118.963 722.198 120.155 722.286 121.345C722.373 122.535 722.774 123.681 723.447 124.667C724.118 125.649 725.036 126.438 726.108 126.955C727.18 127.471 728.369 127.696 729.555 127.608L772.487 124.386L796.741 159.956C797.418 160.931 798.34 161.712 799.413 162.219C800.48 162.742 801.665 162.975 802.849 162.897C804.04 162.808 805.186 162.407 806.171 161.733C807.156 161.059 807.945 160.137 808.458 159.061L855.446 61.4689C857.782 56.6159 858.093 51.0321 856.309 45.946C854.524 40.8599 850.792 36.688 845.932 34.3482ZM801.323 142.627L781.473 113.535C780.802 112.553 779.885 111.764 778.813 111.247C777.74 110.731 776.552 110.506 775.365 110.594L740.245 113.22L782.152 26.18C782.93 24.5623 784.321 23.3207 786.017 22.7283C787.713 22.1359 789.576 22.2413 791.196 23.0212L840.059 46.5472C841.679 47.3271 842.923 48.7177 843.518 50.4131C844.112 52.1085 844.009 53.9697 843.23 55.5874L801.323 142.627Z"
      fill="#D84C42"
    />
    <path
      d="M347.05 160.358L292.261 184.098C286.812 186.459 282.522 190.885 280.337 196.402C278.151 201.919 278.247 208.076 280.605 213.518L328.019 322.946C328.536 324.154 329.364 325.203 330.418 325.987C331.472 326.771 332.715 327.263 334.021 327.413C335.322 327.562 336.64 327.366 337.842 326.846C339.045 326.325 340.089 325.497 340.87 324.446L369.114 286.376L416.205 291.804C417.505 291.942 418.818 291.737 420.015 291.209C421.218 290.698 422.266 289.88 423.054 288.837C423.838 287.781 424.329 286.538 424.477 285.232C424.626 283.927 424.427 282.606 423.9 281.403L376.486 171.974C374.128 166.532 369.702 162.251 364.182 160.073C358.662 157.895 352.499 157.997 347.05 160.358ZM405.076 275.505L366.552 271.077C365.251 270.928 363.933 271.123 362.731 271.644C361.529 272.165 360.485 272.993 359.704 274.044L336.589 305.18L294.302 207.583C293.516 205.769 293.484 203.717 294.213 201.878C294.941 200.039 296.371 198.563 298.188 197.776L352.977 174.037C354.793 173.25 356.847 173.216 358.687 173.942C360.528 174.668 362.003 176.095 362.789 177.909L405.076 275.505Z"
      fill="#FDDA5D"
    />
    <path
      d="M1020.81 199.967L975.457 168.718C970.946 165.61 965.387 164.419 960.003 165.407C954.619 166.394 949.851 169.48 946.747 173.984L884.336 264.568C883.644 265.563 883.228 266.723 883.13 267.931C883.032 269.138 883.255 270.351 883.777 271.446C884.296 272.537 885.093 273.472 886.089 274.158C887.084 274.843 888.242 275.255 889.446 275.352L933.031 278.832L951.807 318.319C952.334 319.403 953.137 320.331 954.134 321.008C955.123 321.699 956.275 322.119 957.476 322.225C958.685 322.322 959.898 322.099 960.991 321.577C962.085 321.055 963.021 320.253 963.704 319.253L1026.12 228.669C1029.22 224.165 1030.4 218.61 1029.41 213.227C1028.41 207.845 1025.32 203.075 1020.81 199.967ZM959.114 301.646L943.745 269.349C943.225 268.258 942.428 267.323 941.433 266.638C940.438 265.952 939.28 265.54 938.075 265.443L902.422 262.585L958.086 181.797C959.12 180.295 960.709 179.267 962.504 178.937C964.299 178.608 966.152 179.005 967.655 180.041L1013.01 211.29C1014.51 212.326 1015.54 213.916 1015.88 215.71C1016.21 217.504 1015.81 219.356 1014.78 220.857L959.114 301.646Z"
      fill="#33A0DD"
    />
    <path
      d="M524.013 5.57802L467.276 18.9758C461.634 20.3083 456.75 23.8248 453.701 28.7516C450.652 33.6785 449.687 39.6121 451.017 45.2472L477.776 158.566C478.066 159.815 478.681 160.965 479.559 161.9C480.438 162.835 481.548 163.522 482.777 163.89C484.002 164.257 485.303 164.296 486.548 164.002C487.792 163.708 488.938 163.092 489.869 162.215L523.558 130.482L567.883 143.794C569.108 144.15 570.406 144.179 571.646 143.877C572.891 143.592 574.038 142.986 574.975 142.119C575.91 141.239 576.596 140.129 576.963 138.9C577.33 137.671 577.365 136.367 577.066 135.12L550.307 21.8011C548.976 16.166 545.458 11.291 540.527 8.24857C535.596 5.20615 529.656 4.24553 524.013 5.57802ZM559.988 126.217L523.725 115.339C522.501 114.972 521.2 114.933 519.955 115.227C518.71 115.521 517.565 116.137 516.633 117.013L489.067 142.964L465.202 41.8978C464.758 40.0194 465.08 38.0415 466.096 36.3992C467.113 34.757 468.74 33.5848 470.621 33.1406L527.358 19.7429C529.239 19.2987 531.219 19.6189 532.863 20.633C534.507 21.6472 535.679 23.2722 536.123 25.1505L559.988 126.217Z"
      fill="#61B195"
    />
    <path
      d="M1314.66 400.159L1223.02 361.498C1213.9 357.653 1203.64 357.585 1194.47 361.31C1185.31 365.035 1178 372.247 1174.16 381.359L1096.85 564.608C1095.99 566.623 1095.68 568.829 1095.95 571.003C1096.22 573.178 1097.05 575.242 1098.38 576.989C1099.69 578.729 1101.44 580.091 1103.46 580.94C1105.47 581.788 1107.67 582.092 1109.83 581.822L1188.2 571.98L1235.84 634.98C1237.17 636.706 1238.93 638.053 1240.94 638.885C1242.94 639.746 1245.13 640.066 1247.29 639.813C1249.47 639.541 1251.53 638.7 1253.28 637.375C1255.02 636.049 1256.38 634.286 1257.23 632.265L1334.53 449.016C1338.38 439.903 1338.44 429.636 1334.72 420.474C1330.99 411.311 1323.78 404.004 1314.66 400.159Z"
      fill="#F99746"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1286.63 501.968C1283.35 509.727 1278.59 516.765 1272.59 522.681C1266.6 528.596 1259.5 533.273 1251.7 536.445C1243.9 539.617 1235.55 541.221 1227.13 541.166C1218.71 541.112 1210.38 539.399 1202.63 536.125C1194.33 532.627 1184.73 528.088 1180.48 519.669C1175.63 510.061 1178.87 499.418 1184.82 491.096C1187.7 486.887 1191.56 483.438 1196.06 481.046C1200.56 478.653 1205.58 477.388 1210.68 477.357C1215.19 477.46 1219.64 478.434 1223.79 480.225C1232.06 483.718 1236.15 488.311 1239.52 492.093C1241.87 494.74 1243.88 496.989 1246.71 498.186C1251.35 500.14 1256.52 499.15 1258.86 493.617C1262.71 484.487 1250.48 469.623 1232.52 462.047C1203.41 449.769 1176.98 460.708 1163.79 483.969C1162.73 474.234 1163.92 464.385 1167.26 455.179C1170.59 445.973 1176 437.654 1183.05 430.859C1190.1 424.065 1198.61 418.976 1207.94 415.982C1217.26 412.989 1227.15 412.17 1236.84 413.59C1246.53 415.009 1255.76 418.629 1263.84 424.171C1271.91 429.713 1278.61 437.031 1283.41 445.563C1288.22 454.094 1291.01 463.614 1291.57 473.39C1292.12 483.167 1290.43 492.942 1286.63 501.965L1286.63 501.968ZM1198.16 517.118C1203.21 519.245 1209.02 516.882 1211.14 511.839C1213.27 506.797 1210.91 500.985 1205.87 498.858C1200.82 496.73 1195.01 499.094 1192.88 504.136C1190.76 509.178 1193.12 514.99 1198.16 517.118ZM1237.65 523.02C1235.52 528.062 1229.71 530.426 1224.67 528.298C1219.62 526.171 1217.26 520.359 1219.39 515.317C1221.52 510.274 1227.33 507.911 1232.37 510.038C1237.41 512.165 1239.78 517.978 1237.65 523.02Z"
      fill="white"
    />
    <path
      d="M134.08 356.054L36.5673 390.865C26.869 394.327 18.9431 401.499 14.533 410.803C10.1229 420.108 9.58999 430.782 13.0514 440.478L82.6584 635.465C83.4165 637.615 84.7294 639.527 86.4643 641.007C88.1992 642.487 90.2944 643.482 92.5378 643.891C94.7726 644.3 97.0756 644.112 99.2152 643.348C101.355 642.585 103.256 641.271 104.727 639.54L157.932 576.854L238.807 591.676C241.04 592.065 243.337 591.861 245.467 591.084C247.607 590.337 249.514 589.04 250.996 587.325C252.472 585.587 253.464 583.49 253.869 581.246C254.275 579.002 254.08 576.691 253.305 574.546L183.698 379.56C180.237 369.864 173.065 361.94 163.759 357.532C154.454 353.124 143.778 352.592 134.08 356.054Z"
      fill="#F99746"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.196 453.891C187.143 462.146 188.436 470.901 188 479.656C187.564 488.411 185.407 496.994 181.654 504.915C177.9 512.837 172.623 519.941 166.124 525.823C159.625 531.705 152.031 536.249 143.775 539.196C134.952 542.345 124.394 545.616 115.209 542.147C104.724 538.192 99.7232 527.746 98.4329 517.175C97.6817 511.919 98.2152 506.559 99.9878 501.554C101.76 496.55 104.719 492.049 108.611 488.438C112.146 485.342 116.245 482.959 120.684 481.419C129.493 478.274 135.861 478.922 141.105 479.456C144.774 479.829 147.893 480.146 150.912 479.069C155.841 477.309 159.116 472.908 157.014 467.02C153.546 457.305 133.703 454.507 114.593 461.329C83.625 472.384 71.0419 499.373 77.2851 526.5C69.6242 519.775 63.6053 511.385 59.6899 501.973C55.7745 492.561 54.0666 482.377 54.6972 472.203C55.3279 462.028 58.2803 452.133 63.3281 443.277C68.3759 434.42 75.385 426.838 83.8178 421.11C92.2505 415.382 101.883 411.662 111.977 410.235C122.07 408.807 132.356 409.71 142.047 412.874C151.737 416.039 160.574 421.38 167.88 428.489C175.186 435.599 180.767 444.287 184.194 453.887L184.196 453.891ZM126.977 527.751C132.342 525.836 135.139 519.934 133.224 514.568C131.308 509.203 125.406 506.406 120.041 508.321C114.675 510.237 111.878 516.139 113.794 521.504C115.709 526.87 121.611 529.667 126.977 527.751ZM161.427 504.5C163.342 509.866 160.545 515.768 155.18 517.683C149.815 519.598 143.912 516.802 141.997 511.436C140.082 506.071 142.879 500.169 148.244 498.253C153.609 496.338 159.511 499.135 161.427 504.5Z"
      fill="white"
    />
  </svg>
);

export default CuriBookmarksSvg;
