import styled from 'styled-components';

import FoldedHandsSVG from '../../../assets/icons/FoldedHands';
import theme from '../../../assets/theme';
import { CenteredContainer } from '../../styled/CenteredContainer';
import { EmojiWrapper } from '../../styled/EmojiWrapper';

const LiveEmptyState = ({
  icon = <FoldedHandsSVG />,
  title,
  description,
  style,
  children,
  maxWidth,
}: {
  icon?: React.ReactNode;
  title: string;
  description?: string | React.ReactNode;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  maxWidth?: number;
}) => {
  //TODO: This is not fully responsive with all states, as the emoji somtimes overlaps the text,
  return (
    <EmptyStateContainer style={style ? style : {}}>
      <CenteredContainer>
        <EmojiWrapper>{icon}</EmojiWrapper>
      </CenteredContainer>
      <div style={{ maxWidth: maxWidth ? maxWidth : 300, margin: 'auto' }}>
        <h3
          style={{
            width: '100%',
            textAlign: 'center',
            marginTop: 20,
            marginBottom: 0,
            fontSize: 22,
            color: theme.colors.black,
          }}
        >
          {title}
        </h3>
        <p
          style={{
            width: '100%',
            textAlign: 'center',
            marginTop: 10,
            fontSize: 16,
            color: '#828282',
            fontWeight: 'bold',
          }}
        >
          {/* Complete a live activity to view analytics and results */}
          <span style={{ whiteSpace: 'pre-wrap' }}>{description}</span>
          {children}
        </p>
      </div>
    </EmptyStateContainer>
  );
};

const EmptyStateContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  min-height: 250px;
  border: 1px dashed ${theme.colors.black};
  border-radius: 10px;
  background-color: ${theme.colors.backgroundTransparent};
  padding: 30px;
  h3 {
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export default LiveEmptyState;
